import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators/tap';
import { switchMap, retryWhen, delayWhen, retry, delay, catchError } from 'rxjs/operators';
import { of, timer } from 'rxjs';
import { UserService } from '../data/users.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private userService: UserService, private router: Router, private route: ActivatedRoute) {
  }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          }
        }),
        switchMap(
          r => this.userService.getUserData()
        ),
        switchMap(user => {
          console.log(user);
          if (!user) {
            return of(false);
          }
          if (!user.id) {
            return this.userService.getUser().pipe(
              tap(
                user => {
                  if (!user.id) {
                    return of(false);
                  } else {
                    return of(true);
                  }
                }
              )
            );
          } else {
            return of(true);
          }
        })
      );
  }
}
