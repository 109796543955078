import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { NbAuthService } from '@nebular/auth/services/auth.service';
import { NbAuthSimpleToken, NB_AUTH_INTERCEPTOR_HEADER, NbAuthJWTToken } from '@nebular/auth';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpEvent } from '@angular/common/http';
import { switchMap } from 'rxjs/internal/operators/switchMap';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private injector: Injector,
        @Inject(NB_AUTH_INTERCEPTOR_HEADER) protected headerName: string = 'Authorization') {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return this.authService.getToken()
            .pipe(
                switchMap((token: NbAuthSimpleToken) => {
                    //console.log('before', req);
                    if (token && token.getValue()) {
                        req = req.clone({
                            headers:
                                req.headers.set('token', token.getValue()),
                        });
                        console.log('Sending req', req);
                    }
                    return next.handle(req);
                }),
        );
    }

    protected get authService(): NbAuthService {
        return this.injector.get(NbAuthService);
    }
}
/*
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth/services/auth.service';
import { NbAuthSimpleToken } from '@nebular/auth';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        console.log(req);
        // Get the auth token from the service.
        const authToken = this.user.token || '';

        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.

        const authReq = req.clone({
            headers: req.headers.set('token', authToken),
        });
        // tslint:disable-next-line:no-console
        console.log('basic-auth>>>>>>', authToken);
        // console.log('xauth-token>', xAuthToken);
        // send cloned request with header to the next handler.
        return next.handle(authReq);
    }
    user;

    constructor(private authService: NbAuthService) {

        this.authService.onTokenChange()
            .subscribe((token: NbAuthSimpleToken) => {

                if (token.isValid()) {
                    this.user = token;
                    // tslint:disable-next-line:no-console
                }

            });
    }
}*/
