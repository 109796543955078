import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../data/users.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MailService {

  ENV: any;
  API_URL: string;
  user_data;
  constructor(private authService: NbAuthService, private http: HttpClient,
    private router: Router, private user: UserService) {
    this.ENV = environment;
    this.API_URL = this.ENV.API_URL;
    this.user.getUserData().subscribe(
      r => this.user_data = user,
      e => this.router.navigate(['/auth/login']),
    )
  }
  sendMail(data): Observable<any> {
    return this.http.post(this.API_URL + '/mail/send', data)
  }
  getMail(): Observable<any> {
    return this.http.get(this.API_URL + '/mail/list')
  }
  addMail(data): Observable<any> {
    return this.http.post(this.API_URL + '/mail/addmail', {'name': data})
  }
}
