import { Category } from "./category.model";

interface UserInterface {
    id: string;
    name: string;
    surname: string;
    username: string;
    mail: string;
    categories: Array<Category>;
}
export class User implements UserInterface {
    id: string = null;    
    name: string = null;
    surname: string = null;
    username: string = null;
    mail: string = null;
    categories: Category[] = null;
    role: number = null;
}
