import { UserService } from './data/users.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {

  ENV: any;
  API_URL: string;
  user_data;
  constructor(private http: HttpClient, private router: Router, private user: UserService) {
    this.ENV = environment;
    this.API_URL = this.ENV.API_URL;
    this.user.getUserData().subscribe(
      r => {
        this.user_data = r;
      },
      e => this.router.navigate(['/auth/login']),
    )
  }
  subs(id, mail): Observable<any> {
    return this.http.post(this.API_URL + '/mail/subs', {
      'iduser': id,
      'idmail': mail,
    })
  }
  subs_remove(id, mail): Observable<any> {
    return this.http.delete(this.API_URL + '/mail/subs', {
      params: {
        'iduser': id,
        'idmail': mail,
      },
    })
  }
  get_all_subs(): Observable<any> {
    return this.http.get(this.API_URL + '/mail/list', {})
  }
  get_subs(): Observable<any> {
    if (!this.user_data || this.user_data.id === '') {
      this.user.getUserData().subscribe(
        r => {
          this.user_data = r;
          return this.http.get(this.API_URL + '/mail/subs/' + this.user_data.id);
        },
        e => this.router.navigate(['/auth/login']),
      )
    } else {
      return this.http.get(this.API_URL + `/mail/subs/${this.user_data.id}`);
    }
  }
}

