import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { NbAuthService, NbAuthSimpleToken, NbTokenService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import { map } from 'rxjs/operators';



@Injectable()
export class UserService {



  ENV: any;
  API_URL: string;
  API_FILE_URL: string;
  private user = new BehaviorSubject<any>(new User());
  user$ = this.user.asObservable();
  modtoken;
  constructor(private authService: NbAuthService, private tokenService: NbTokenService,
    private http: HttpClient, private router: Router) {
    this.ENV = environment;
    this.API_URL = this.ENV.API_URL;
    this.API_FILE_URL = this.ENV.API_FILE_URL;
    this.authService.onTokenChange()
      .subscribe((token: NbAuthSimpleToken) => {

        if (token.isValid()) {
          this.getUser().subscribe(
            r => {
              this.user.next(r);
            },
            e => this.router.navigate(['/auth/login']),
          );
        }
      });
  }

  getUser(): Observable<any> {
    if(this.modtoken){
      this.modtoken = false;
      return of(this.user.getValue());
    }
    return this.http.get(this.API_URL + '/user/info').pipe(
      map(
        (r: any) => ({ ...r.user, categories: r.categories })
      )
    );
  }
  getUsers(): Observable<any> {
    return this.http.get(this.API_URL + '/users');
  }

  getUserData(): Observable<User> {
    return this.user$;
  }

  modUser(userData) {
    return this.http.put(this.API_URL + '/user/' + userData.newData.id, { old_data: this.user.getValue(), new_data: userData.newData },{observe: 'response'}).map(
      r => {
        if (r.headers.get('token')) {
          this.modtoken = true;
          this.tokenService.clear();
          this.tokenService.set(new NbAuthSimpleToken(r.headers.get('token'))).subscribe();
          return r.body;
        }
      }
    );
  }
  modAdmin(userData) {
    return this.http.put(this.API_URL + '/user/admin/' + userData.newData.id, { old_data: this.user.getValue(), new_data: userData.newData },{observe: 'response'}).map(
      r => {
        if (r.headers.get('token')) {
          this.modtoken = true;
          this.tokenService.clear();
          this.tokenService.set(new NbAuthSimpleToken(r.headers.get('token'))).subscribe();
        }
        return r.body;
      });
  }
  updatePassword(password) {
    return this.http.put(this.API_URL + '/user/resetpwd', { password: password });
  }
  remUser(userData: any) {
    const options = { headers: { 'id': userData } };
    return this.http.delete(this.API_URL + '/user/' + userData, options);
  }
  logout() {
    return this.http.get(this.API_URL + '/user/logout');
  }
  addUser(user_data) {
    return this.http.post(this.API_URL + '/user/create', user_data);
  }
  createCategory(req) {
    return this.http.post(this.API_URL + '/category', req);
  }
  addCategory(req) {
    return this.http.post(this.API_URL + '/user/categorization', req);
  }
  remCategory(id: any) {
    return this.http.delete(this.API_URL + '/category/' + id);
  }
  takeCategory(req): any {
    const options = { headers: { 'request': JSON.stringify(req) } };
    return this.http.delete(this.API_URL + '/user/categorization', options);
  }
  editCategory(req: any): any {
    return this.http.put(this.API_URL + '/category', req);
  }
  requestResetPassword(email) {
    return this.http.get(this.API_URL + '/user/reset/'+ email);
  }

  checkOtp(otp) {
    return this.http.get(this.API_FILE_URL + 'files/requestReset/'+ otp);
  }

  resetPassword(otp, password) {
    return this.http.get(this.API_FILE_URL + 'files/defreset/'+ otp + '/' + password);
  }

}
